













































import { Component, Vue } from 'vue-property-decorator'
import MaterialSelect from '@/components/material-select/index.vue'
import { apiBrandAdd, apiBrandDetail, apiBrandEdit } from '@/api/goods'
@Component({
    components: {
        MaterialSelect
    }
})
export default class AddBrand extends Vue {
    $refs!: { form: any }
    id!: any
    loading = false
    form = {
        name: '',
        image: '',
        sort: '',
        is_show: 1
    }

    rules = {
        name: [
            {
                required: true,
                message: '请输入品牌名称',
                trigger: ['blur', 'change']
            }
        ],
        image: [
            {
                required: true,
                message: '请添加品牌图标',
                trigger: ['blur', 'change']
            }
        ]
    }

    handleSave() {
        this.$refs.form.validate((valid: boolean, object: any) => {
            if (valid) {
                const api = this.id ? apiBrandEdit(this.form) : apiBrandAdd(this.form)
                api.then(() => {
                    this.$router.go(-1)
                })
            } else {
                return false
            }
        })
    }

    getBrandDetail() {
        this.loading = true
        apiBrandDetail(this.id).then((res: any) => {
            this.form = res
            this.loading = false
        })
    }

    created() {
        this.id = this.$route.query.id
        this.id && this.getBrandDetail()
    }
}
